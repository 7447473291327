<template>
    <v-card flat tile>
        <v-snackbar v-model="showResult" :timeout="30000" top :color="color" tile>
            {{ result }}
        </v-snackbar>
        <v-card-title class="secondary white--text text-h5">
            Mon compte
            <v-btn class="mx-2" dark color="indigo" @click="toggleReadOnly">
                <div>{{ buttonText }}</div>
            </v-btn>
        </v-card-title>
        <v-tabs v-model="tabs" align-with-title dark flat>
            <v-tabs-slider color="yellow" />
            <v-tab class="secondary white--text text-h5">
                Compte utilisateur
            </v-tab>
            <v-tab class="secondary white--text text-h5">
                Compte Société
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-img v-if="avatar" :src="photoprofile" alt="" max-width="300" :aspect-ratio="1" contain />
                        <v-icon v-else x-large class="blue--text">
                            mdi-account
                        </v-icon>
                        <v-file-input v-if="!isReadOnly" accept="image/png, image/jpeg" prepend-icon="mdi-camera"
                            label="Changer de photo de profil" @change="photoprofilchange" />
                        <v-text-field v-model="modeluser.nom" name="name" :label="$t('login.name')" required
                            :rules="[rules.required]" autocomplete="username" :readonly="isReadOnly" />
                        <v-text-field v-model="modeluser.firstname" name="firstname" :label="$t('login.firstname')"
                            :rules="[rules.required]" required autocomplete="username" :readonly="isReadOnly" />
                        <v-text-field v-model="modeluser.email" append-icon="mdi-at" name="email"
                            :rules="[rules.required, rules.email]" :label="$t('login.email')" type="email" required
                            autocomplete="username" :readonly="isReadOnly" />
                        <vue-tel-input-vuetify v-model="modeluser.phone" mode="international" placeholder=""
                            :rules="[rules.required]" label="Téléphone" :preferred-countries="['fr', 'gb']"
                            :readonly="isReadOnly" />
                        <v-text-field v-model="modeluser.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            name="password" :label="$t('login.password')" :rules="[rules.required]"
                            :type="show1 ? 'text' : 'password'" required autocomplete="new-password" :readonly="isReadOnly"
                            @click:append="show1 = !show1" />
                        <v-text-field v-model="modeluser.confirmPassword" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            name="confirm" :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                            :label="$t('login.confirm')" :type="show2 ? 'text' : 'password'" required
                            autocomplete="new-password" :readonly="isReadOnly" @click:append="(show2 = !show2)" />
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-text-field v-model="modelsociete.siret" name="siret" label="Numéro de siret" required
                            :readonly="isReadOnly" @input="siret" />
                        <v-row>
                            <v-col>
                                <v-text-field v-model="modelsociete.name" name="nom_entreprise"
                                    label="Nom de votre entreprise" :readonly="isReadOnly" />
                            </v-col>
                            <v-col>
                                <v-text-field v-model="modelsociete.nameCom" name="nom_commercial"
                                    label="Nom Commercial (si différent)" :readonly="isReadOnly" />
                            </v-col>
                        </v-row>
                        <v-text-field v-model="modelsociete.email" append-icon="mdi-account" name="email"
                            :rules="[rules.required, rules.email]" :label="$t('login.email')" type="email"
                            :readonly="isReadOnly" />
                        <vue-tel-input-vuetify v-model="modelsociete.tel_fixe" mode="international" placeholder=""
                            label="Téléphone" :preferred-countries="['fr', 'gb']" :readonly="isReadOnly" />
                        <v-text-field v-model="modelsociete.site" name="site_entreprise" label="Site web"
                            :readonly="isReadOnly" />
                        <v-text-field v-model="modelsociete.adresse" name="adresse_entreprise" label="Adresse"
                            :readonly="isReadOnly" />
                        <v-row>
                            <v-col>
                                <v-text-field v-model="modelsociete.cp" name="cp_entreprise" label="Code postal"
                                    :readonly="isReadOnly" />
                            </v-col>
                            <v-col>
                                <v-text-field v-model="modelsociete.ville" name="ville_entreprise" label="Ville"
                                    :readonly="isReadOnly" />
                            </v-col>
                        </v-row>
                        <v-text-field v-model="modelsociete.siren" name="siren" label="Numéro de siren"
                            :readonly="isReadOnly" />
                        <v-row>
                            <v-col>
                                <v-text-field v-model="modelsociete.statut" name="statut_entreprise"
                                    label="Statut juridique" :readonly="isReadOnly" />
                            </v-col>
                            <v-col>
                                <v-text-field v-model="modelsociete.categorie" name="catégorie_entreprise"
                                    label="Catégorie juridique" :readonly="isReadOnly" />
                            </v-col>
                        </v-row>
                        <v-text-field v-model="modelsociete.tva" name="tva_entreprise" label="Numéro de TVA"
                            :readonly="isReadOnly" />
                        <v-text-field v-model="modelsociete.ape" name="ape_entreprise" label="APE" :readonly="isReadOnly" />
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Profil',
    data: () => ({
        tabs: null,
        items: [
            'Compte utilisateur', 'Compte Société',
        ],
        isReadOnly: true,
        valid: false,
        showResult: false,
        color: 'undefined',
        result: '',
        height: 300,
        show1: false,
        show2: false,
        photoprofile: null,
        photoprofiletoupload: null,
        modeluser: {
            email: '',
            password: '',
            confirmPassword: '',
            firstname: '',
            nom: '',
            phone: ''
        },
        modelsociete: {
            email: '',
            nameCom: '',
            name: '',
            tel_fixe: '',
            siret: '',
            siren: '',
            tva: '',
            adresse: '',
            cp: '',
            ville: '',
            ape: '',
            statut: '',
            categorie: '',
            site: '',
            pays: '',
        },
        confirmPasswordRules: [(v) => !!v || ''],
        rules: {
            required: (value) => !!value || 'Obligatoire.',
            email: (value) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Email invalide.';
            },
        },
    }),
    computed: {
        passwordConfirmationRule() {
            return () => this.modeluser.password === this.modeluser.confirmPassword || 'Les mots de passe doivent correspondre';
        },
        buttonText() {
            return this.isReadOnly ? 'Modifier' : 'Enregistrer';
        },
        ...mapGetters([
            'user',
            'name',
            'avatar',
        ]),
    },
    mounted() {
        this.getuserinfo();
        this.photoprofile = this.avatar;
    },
    methods: {
        photoprofilchange(fileObject) {
            const vm = this;
            const reader = new FileReader();
            reader.onload = (e) => {
                vm.photoprofiletoupload = e.target.result;
                vm.photoprofile = e.target.result;
            };
            reader.readAsDataURL(fileObject);
        },
        async siret() {
            const vm = this;
            if (vm.modelsociete.siret.length === 14) {
                const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_sys_api_siret`;
                const bodyFormData = new FormData();
                bodyFormData.append('numero_siret', vm.modelsociete.siret);
                const response = await vm.$axios.post(url, bodyFormData);
                const result = response.data;
                if (result.header === 'Succès') {
                    vm.modelsociete.siren = result.siretreponse.etablissement.siren;
                    vm.modelsociete.ville = result.siretreponse.etablissement.adresseEtablissement.libelleCommuneEtablissement;
                    vm.modelsociete.adresse = `${result.siretreponse.etablissement.adresseEtablissement.numeroVoieEtablissement} ${result.siretreponse.etablissement.adresseEtablissement.typeVoieEtablissement} ${result.siretreponse.etablissement.adresseEtablissement.libelleVoieEtablissement}`;
                    vm.modelsociete.name = result.siretreponse.etablissement.uniteLegale.denominationUniteLegale;
                    vm.modelsociete.cp = result.siretreponse.etablissement.adresseEtablissement.codePostalEtablissement;
                }
            }
        },
        async toggleReadOnly() {
            if (!this.isReadOnly) {
                await this.register();
            }
            this.isReadOnly = !this.isReadOnly;
        },
        async getuserinfo() {
            const vm = this;
            const response = await vm.$store.dispatch('GetUserInfo', {
                config: vm.$config,
                axios: vm.$axios
            });
            vm.uuid = response.user.UUID;
            vm.modeluser.nom = response.user.nom;
            vm.modeluser.firstname = response.user.prenom;
            vm.modeluser.email = response.user.email;
            vm.modeluser.phone = response.user.phone;

            vm.modelsociete.siret = response.societe.numero_siret;
            vm.modelsociete.email = response.societe.email_entreprise;
            vm.modelsociete.nameCom = response.societe.nom_commercial;
            vm.modelsociete.name = response.societe.Nom;
            vm.modelsociete.tel_fixe = response.societe.tel_fixe;
            vm.modelsociete.siret = response.societe.numero_siret;
            vm.modelsociete.siren = response.societe.numero_siren;
            vm.modelsociete.tva = response.societe.num_tva;
            vm.modelsociete.adresse = response.societe.adresse1;
            vm.modelsociete.cp = response.societe.CP;
            vm.modelsociete.ville = response.societe.ville;
            vm.modelsociete.ape = response.societe.nom_ape;
            vm.modelsociete.statut = response.societe.nom_statut;
            vm.modelsociete.categorie = response.societe.categorie;
            vm.modelsociete.site = response.societe.site_web;
            vm.modelsociete.pays = response.societe.pays;

            console.log(response);
        },
        async register() {
            const vm = this;
            if (vm.modeluser.email === '' || vm.modelsociete.email === '') {
                vm.showResult = true;
                vm.color = 'red';
                vm.result = 'Veuillez renseigner les champs nécessaires';
                return;
            }
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_sys_web_modifuser`;
            const bodyFormData = new FormData();
            bodyFormData.append('nom_register', vm.modeluser.nom);
            bodyFormData.append('prenom_register', vm.modeluser.firstname);
            bodyFormData.append('email_register', vm.modeluser.email);
            bodyFormData.append('mdp_register', vm.modeluser.password);
            bodyFormData.append('phone', vm.modeluser.phone);

            bodyFormData.append('nom_entreprise_register', vm.modelsociete.name);
            bodyFormData.append('nomCom_entreprise_register', vm.modelsociete.nameCom);
            bodyFormData.append('email_entreprise_register', vm.modelsociete.email);
            bodyFormData.append('site_entreprise_register', vm.modelsociete.site);
            bodyFormData.append('tel_fixe', vm.modelsociete.tel_fixe);
            bodyFormData.append('adresse1_entreprise_register', vm.modelsociete.adresse);
            bodyFormData.append('cp_entreprise_register', vm.modelsociete.cp);
            bodyFormData.append('ville_entreprise_register', vm.modelsociete.ville);
            bodyFormData.append('numero_siret_register', vm.modelsociete.siret);
            bodyFormData.append('numero_siren_register', vm.modelsociete.siren);
            bodyFormData.append('statut_entreprise_register', vm.modelsociete.statut);
            bodyFormData.append('ape_entreprise', vm.modelsociete.ape);
            bodyFormData.append('numtva_entreprise_register', vm.modelsociete.tva);
            bodyFormData.append('categorie', vm.modelsociete.categorie);
            bodyFormData.append('useruuid', vm.uuid);

            if ((vm.photoprofiletoupload !== null) && (vm.photoprofiletoupload.length > 0)) {
                bodyFormData.append('photoprofile', vm.createblob(vm.photoprofiletoupload));
            }

            const response = await vm.$axios.post(url, bodyFormData);
            const result = response.data;
            if (result.header === 'Succès') {
                vm.result = 'Votre compte a bien été modifié !';
                vm.color = 'green';
                vm.showResult = true;
            } else {
                vm.error = true;
                vm.result = 'Erreur, le compte n\'a pas pu être modifié';
                vm.showResult = true;
                vm.color = 'grey';
            }
        },
        createblob(srcoffile) {
            let encoded = srcoffile.replace(/^data:(.*;base64,)?/, '');
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            return encoded;
        }
    }
};
</script>
